<template>
  <div class="intro">
    <template v-if="info">
    <div class="intro__content container-xl">
      <div class="row">
        <div class="col-7">
          <h4>
            {{ getData.welcome }}, {{ companyContact.firstname }}
          </h4>
          <h1 class="title title--big" v-html="getData.headline"></h1>
          <div class="mb-4" v-html="getData.text"></div>

          <Button
            v-if="getDataByPrinter.video"
            href="javascript:void(0)"
            data-gtm-section="Intro"
            data-gtm-subsection=""
            data-gtm-label="Watch Intro Video"
            data-target="#intro-video"
            data-toggle="modal"
            @click="clickTracked($event)">
            {{ $t("buttons.watch-video") }}
          </Button>
        </div>
      </div>
    </div>

    <div class="intro__bg">
      <img :src="`${cmsURL}assets/${getDataByPrinter.background}`" role="none" />
    </div>

    <Modal
        v-if="getDataByPrinter.video"
        idModal="intro-video">
      <Media
        data-gtm-section="Intro"
        data-gtm-label="Play Intro Video"
        :poster="`${cmsURL}assets/${getDataByPrinter.background}`"
        :src="getDataByPrinter.video" />
    </Modal>
    </template>

    <!-- Home Modal -->
    <HomeModal :display="true" />
    <!-- End Home Modal -->
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { defaultMixins } from '@/common/mixins';

import HomeModal from '@/components/HomeModal.vue';
import Button from '@/components/ui/Button.vue';
import Modal from '@/components/modal/Modal.vue';
import Media from '@/components/ui/Media.vue';

export default {
  name: 'Intro',
  mixins: [defaultMixins],
  components: {
    Button,
    Modal,
    Media,
    HomeModal
  },
  data() {
    return {
      cmsURL: endpoints.CMS,
      info: null
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/intro`, {
      params: {
        fields: '*,translations.*,printer.printers_id'
      }
    },
    {
      'Cache-Control': 'no-cache',
      Expires: '-1'
    })
      .then(({ data: { data } }) => {
        this.info = data;
      })
      .catch((error) => console.error(error));
  },
  mounted() {
    this.showMainTabs();
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/intro.scss';
</style>
